import { Injectable } from '@angular/core';
import { ToastInfo } from '@app/shared/interfaces/toast-info.interface';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  toasts: ToastInfo[] = [];

  show(
    header: string,
    body: string,
    translate = false,
    translateParams?: Record<string, string | number>,
  ) {
    this.toasts.push({ header, body, translate, translateParams });
  }

  error(
    header: string,
    body: string,
    translate = false,
    translateParams?: Record<string, string | number>,
  ) {
    this.toasts.push({
      header,
      body,
      translate,
      type: 'danger',
      translateParams,
    });
  }

  remove(toast: ToastInfo) {
    this.toasts = this.toasts.filter((t) => t !== toast);
  }

  clear() {
    this.toasts = [];
  }
}
